import React from "react"
import "../../components/my_global.scss"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { Container, Row, Col } from "reactstrap"
import stick from "../../images/stick.webp"
import "../../components/prizes.css"
// images
import { StaticImage } from "gatsby-plugin-image"

const Fast = () => {
  return (
    <Layout>
      <SEO title={"What We're Reading"} />
      <div className="page-heading reading-bg">
        <Container>
          <Row>
            <Col lg="8" className="mx-auto text-center text-white">
              <h3 className="h3s"> American-Made Challenges</h3>
              <h2 className="h2s">
                FAST Commissioning for Pumped-Storage Hydropower Prize
              </h2>
              <p
                className="page-hero-subheading"
                style={{ textAlign: "justify" }}
              >
                The U.S. Department of Energy (DOE) Water Power Technologies
                Office (WPTO) invites you to share your ideas to reduce the
                time, cost, and risk from concept to commissioning, excluding
                permitting, for pumped-storage hydropower (PSH). Finalists will
                receive vouchers for up to 50 hours of support from one of four
                DOE national laboratories to prepare for a pitch contest which
                will result in up to 3 winners and up to $550,000 of combined
                cash prizes and voucher support.
              </p>
              <div className="column is-12">
                <div className="has-text-centered">
                  <a href="https://www.herox.com/FAST">
                    <button type="button" className="btns">
                      Follow the Challenge?
                    </button>
                  </a>
                </div>
                <span className="whitespace"></span>
              </div>
            </Col>
          </Row>
          <StaticImage src="../../images/stick.webp" className="stick" alt="stick" loading="lazy"/>
        </Container>
      </div>
      <div className="section_padding">
        <div className="container">
          <h1 className="mac_h1">
            About the American-Made FAST Commissioning for Pumped-Storage
            Hydropower Prize
          </h1>
          <p className="pcol-fz">
            The Furthering Advancements to Shorten Time (FAST) Commissioning for
            Pumped-Storage Hydropower (PSH) Prize aims to attract ideas to
            reduce the time, cost, and risk required to commission PSH projects.
            This is the first prize of its kind and is structured to support
            anyone with a great idea and the motivation to develop their idea
            into a full business concept.
          </p>
          <p className="pcol-fz">
            Today’s electricity system is changing rapidly and hydropower and
            PSH have an essential role in contributing to the resilience,
            reliability, and affordability of the U.S. power system. PSH is by
            far the largest source of energy storage on the grid, and it will
            play a key role in supporting increased integration of variable
            generation resources. But large capital investments and long lead
            times for PSH commissioning are deterrents to would-be developers
            and utilities.
          </p>
          <p class="pcol-fz">
            The goal of the prize is to catalyze new solutions, designs, and
            strategies to accelerate PSH development by reducing the time, cost
            and risk to commission PSH. Ideas could include innovative PSH
            ideas, new layouts, creative construction management, improved
            construction equipment, application of advanced manufacturing, or
            standardization of equipment.
          </p>
        </div>
        <span style={{ padding: "5px" }}></span>
        <div className="container">
          <h1 className="mac_h1">Contest Winners</h1>
          <hr />
          <p className="pcol-fz">
            Congratulations to the four grand prize winners, who received up to
            $550,000 in vouchers and cash prizes! They are:
          </p>
          <div>
            <table class="table table-hover">
              <thead class="has-bg-primary-blue">
                <tr>
                  <th class="has-text-bright">
                    entry title{" "}
                    <span class="is-size-7 has-text-weight-normal">
                      (listed alphabetically)
                    </span>
                  </th>
                  <th class="has-text-bright">team</th>
                  <th class="has-text-bright tds">innovation</th>
                </tr>
              </thead>
              <tbody class="has-text-default">
                <tr>
                  <td>Accelerating PSH Construction with Steel Dams</td>
                  <td>Gordon Wittmeyer, Southwest Research Institute</td>
                  <td className="tds">
                    Presented a modular steel concept for dams that cuts costs
                    by one-third and cuts construction schedules in half.
                  </td>
                </tr>
                <tr>
                  <td>Modular Closed-Loop Scalable Pump Storage Hydro</td>
                  <td>Tom Eldredge and Hector Medina, Liberty University</td>
                  <td className="tds">
                    Presented a modular closed-loop, scalable PSH system with a
                    capacity range of 1–10 megawatt, adaptable to sites without
                    natural bodies of water.
                  </td>
                </tr>
                <tr>
                  <td>Reducing PSH Excavation Duration, Cost, &amp; Risk</td>
                  <td>Tracy Livingston and Thomas Conroy, Team Livingston</td>
                  <td className="tds">
                    Combined excavation equipment modifications and process
                    optimizations to achieve up to 50% reduction in excavation
                    timelines.
                  </td>
                </tr>
                <tr>
                  <td>Use of Modern TBMs for Underground Pumped Storage</td>
                  <td>Doug Spaulding, Nelson Energy and Golder Associates</td>
                  <td className="tds">
                    Proposed use of tunnel boring machines for underground
                    excavation, which can decrease excavation time by 50% and
                    reduce costs.
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <span style={{ padding: "5px" }}></span>
        </div>
        <span style={{ padding: "5px" }}></span>
        <div className="container">
          <div>
            <h1 className="mac_h1">Important Dates</h1>
            <ul className="boxy-bullets gold-bullets ps list-styled">
              <li>
                <b>Release of Prize: April 1, 2019</b>
              </li>
              <li>
                <b>Concept Stage Closes: </b>
                Concept Stage Submission Deadline: June 7, 2019
              </li>
              <li>
                <b>Concept Stage Winners Announcement:</b>
                Notification of Up to 10 Finalists for Incubation: July 2019
              </li>
              <li>
                <b>
                  Incubation Stage Starts: Incubation Support (up to 50 hours of
                  technical assistance):
                </b>
                July – October 2019
              </li>
              <li>
                <b>
                  Incubation Stage Closes: Submission of Final Report and Pitch
                  Deck:
                </b>
                October 2019
              </li>
              <li>
                <b>Pitch Contest Stage: FAST Prize pitch contest:</b>
                October 2019
              </li>
              <li>
                <b>Grand Prize Winner Announcement:</b>
                pitch contest Winners Announced: October 2019
              </li>
              <li>
                <b>
                  Grand Prize Winners Voucher Support: Prize Winners Voucher
                  Period: Voucher support (up to 3 winners and up to $550,000 of
                  combined cash prizes and voucher support):
                </b>
                November 2019 – September 2020
              </li>
            </ul>
            <b className="pcol-fz">
              <b>
                All dates are subject to change including contest openings,
                deadlines, and announcements.
              </b>
            </b>
            <p className="ps">
              Sign up for updates on our{" "}
              <a
                href="https://www.herox.com/FAST"
                onclick="gtag('event', 'action', {'event_category': 'navigated to herox', 'event_label': 'linked to erobot'})"
              >
                HeroX challenge page
              </a>
              .
            </p>
          </div>
          <hr />
        </div>
        <span style={{ padding: "5px" }}></span>
        <div className="container">
          <h3 className="h3ps mac_h1">
            {" "}
            Solutions that help shape the future{" "}
          </h3>
          <p className="hps">
            Are you a thinker, entrepreneur, facility, or potential partner?
            Anyone with an innovative idea can help promote transformation by
            participating in the American-Made Challenges.
          </p>
          <div style={{ textAlign: "center" }}>
            <a href="https://www.herox.com/FAST">
              <button type="button" class="btns">
                Ready for a Challenge?
              </button>
            </a>
          </div>
        </div>

        <span style={{ padding: "5px" }}></span>
      </div>
    </Layout>
  )
}

export default Fast
